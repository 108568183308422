<template>
  <BaseModal
    v-model="model"
    size="md"
    :loading="loading"
    :cancelButtonDisabled="loading"
    :confirmButtonDisabled="loading"
    @confirm-button-click="importExcel"
  >
    <h1 class="font-large-1 text-center">Importar límites financieros</h1>
    <div class="text-center py-1">
      Antes de la carga verificar que el excel esté en el formato correcto
      <b-link
        class="d-flex align-items-center justify-content-center"
        @click="downloadExample"
      >
        aquí hay un ejemplo
      </b-link>
    </div>
    <b-form-group
      label="excel"
      label-for="document"
    >
      <FileDropZone
        id="document"
        ref="documents-input"
        :files="file"
        :max-files="1"
      />
    </b-form-group>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import FinancialLimitReportApi from '@/api/financial-limit-reports-api'

export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      loading: false,
      file: {},
    }
  },
  components: {
    BaseModal,
    FileDropZone,
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    downloadExample() {
      let route = 'ejemplo.xlsx';
      
      const a = document.createElement('a');

      a.href = route;

      a.download = 'ejemplo.xlsx';

      a.click();
    },
    async importExcel() {
      const document = this.$refs['documents-input'].getFiles()

      if(document.length === 0) {
        return;
      }

      this.loading = true
      this.$refs['documents-input'].removeAllFiles()

      try {
        await FinancialLimitReportApi.import(document[0])
      } finally {
        window.location.reload()
      }
    }
  }
}
</script>