import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_FINANCIAL_LIMIT_REPORT } from '@/shared/constants/resources'


class FinancialLimitReportsApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }
  import (document) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${this.resource}/import`, { excel: document })
        Vue.$toast('Importacion realizada con éxito.')
        resolve(response.data.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al importar el documento. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
}

export default new FinancialLimitReportsApi(RESOURCE_FINANCIAL_LIMIT_REPORT)